/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 24.04.2019, 11:38:26
    Author     : wolfgang
*/


// Generell uikit
.rex-var-dumper {
    :not(pre) > code,
    :not(pre) > kbd,
    :not(pre) > samp {
        white-space: pre;
        background-color: inherit;
    }
}
    
.uk-card-default {
    border: 1px solid #ccc;
    box-shadow: none;
}

article.uk-container {
    border-right: 2px solid #ccc;
    border-left: 2px solid #ccc;
    min-height: calc(100vh - 180px);
    box-sizing: border-box;
}

.order_count {
    text-align: center;
    width: 50px;
}

.wh_logo {
    width: 120px;
    height: auto;
}

label.switch_count {
    width: 38px;
}

.wh_prod_image {
    border: 1px solid #ccc;
}


#main_nav {
    .lev2 {
        a {
            white-space: nowrap;
            color: #05008c;
            padding-top: 4px;
            padding-bottom: 4px;
        }
    }
}

#nav-offcanvas {
    .uk-card-header {
        min-height: 30px;
    }
    .uk-offcanvas-bar {
        border-right: 1px solid #ccc;
        *, a {
            color: #555;
            &:hover {
              color: #555;            
            }
        }
        background-color: #fff;
        color: #555;
    }
    ul li a {
        border-top: 1px solid #ccc;
        padding-top: 6px;
        padding-bottom: 6px;
    }
    .uk-list-divider > li {
        padding: 0;
        margin: 0;
    }
    .lev1 {
        > li {
            > a {
                font-weight: bold;
            }
        }
        border-bottom: 1px solid #ccc;
    }
    .lev3 {
        a {
            padding-left: 12px;
        }
    }
}



#cart-offcanvas {
    background-color: #fff;
    border-left: 1px solid #ccc;
}

// Cart Page

.wh-qty-input {
    text-align: center;
    width: 60px;
}

.wh-cart-quantity-column {
    white-space: nowrap;
}

input.uk-radio {
    margin-right: 10px;
}

.tm-variations>.uk-active>a {
    color: #666;
    border-color: #1e87f0;
    background-color: transparent;
}